import React from "react";
import ReactDOM from "react-dom/client";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { HomeSearch } from "../src/HomeSearch/HomeSearch";
import { GoogleScriptsProvider } from "../src/shared/GoogleScriptsContext";

Bugsnag.start({
  apiKey: window.AppConfig.BUGSNAG_FRONTEND_API_KEY || "invalid",
  plugins: [new BugsnagPluginReact()],
  releaseStage: window.AppConfig.RAILS_ENV,
  enabledReleaseStages: ["production", "staging"],
});

const googleScriptsLibraries = ["places" as const];

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("searchHero");

  if (!node) {
    return;
  }

  const data = JSON.parse(node.getAttribute("data") || "{}");

  const area = data.area;
  const scanTypes = data.scanTypes;
  const selectedScanType =
    scanTypes.length === 1 ? scanTypes[0] : data.selectedScanType;

  const availableBodyParts = data.availableBodyParts ?? [];
  const selectedBodyParts = data.selectedBodyParts ?? [];
  const showConsultationFooter = Boolean(data.showConsultationFooter);

  const root = ReactDOM.createRoot(node);

  root.render(
    <GoogleScriptsProvider libraries={googleScriptsLibraries}>
      <HomeSearch
        initialLocation={area}
        scanTypes={scanTypes}
        initialScanType={selectedScanType}
        initialBodyParts={availableBodyParts}
        selectedBodyParts={selectedBodyParts}
        showConsultationFooter={showConsultationFooter}
      />
    </GoogleScriptsProvider>
  );
});

import React, { useEffect, useRef, useState } from "react";
import { BodyPartInput } from "../NewSearchResults/SearchForm/BodyPartsInput";
import { LocationInput } from "../NewSearchResults/SearchForm/LocationInput";
import { ScanTypeInput } from "../NewSearchResults/SearchForm/ScanTypeInput";
import { useBodyParts } from "../NewSearchResults/SearchForm/useBodyParts";
import { BodyPart, ScanType } from "../NewSearchResults/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SystemMessage } from "../shared/SystemMessage";
import { inputWrapperStyles } from "../NewSearchResults/SearchForm/inputStyles";

interface HomeSearchProps {
  scanTypes: ScanType[];
  initialScanType?: ScanType;
  initialBodyParts: BodyPart[];
  selectedBodyParts?: BodyPart[];
  initialLocation?: string;
  showConsultationFooter?: boolean;
}

export interface SearchFormFields {
  body_parts: BodyPart["id"][];
  scan_type: ScanType["id"] | undefined;
  area: string;
}

const csrfToken = document
  .querySelector("meta[name='csrf-token']")
  ?.getAttribute("content");

export function HomeSearch({
  initialScanType,
  scanTypes = [],
  initialBodyParts = [],
  initialLocation,
  selectedBodyParts = [],
  showConsultationFooter,
}: HomeSearchProps) {
  const form = useForm<SearchFormFields>({
    defaultValues: {
      body_parts: selectedBodyParts.map((bp) => bp.id),
      scan_type: initialScanType?.id,
      area: initialLocation,
    },
  });

  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);

  const selectedScanTypeId = form.watch("scan_type") ?? "";

  const { bodyParts } = useBodyParts({
    initialBodyParts,
    selectedScanTypeId,
    onFetchSuccess: (bodyParts) => {
      // when the available body parts changes, update the selections in the form to
      // include only the body parts that are valid for the current scan type. Also
      // adjust the max selected body parts for the scan type
      const currentBodyPartIds = form.getValues("body_parts");
      const availableBodyPartIds = bodyParts.map(({ id }) => id);

      form.setValue(
        "body_parts",
        currentBodyPartIds
          .filter((id) => availableBodyPartIds.includes(id))
          .slice(0, selectedScanType?.max_allowed_body_parts)
      );
    },
  });

  const selectedScanType = scanTypes.find(
    ({ id }) => selectedScanTypeId === id
  );

  const errors = form.formState.errors;
  const formError = Object.values(errors)[0]?.message;

  const onSubmit: SubmitHandler<SearchFormFields> = () => {
    setLoading(true);
    if (formRef.current) {
      window.sessionStorage.setItem("search_initiated_at", `${Date.now()}`);
      formRef.current.submit();
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (bodyParts.length === 1) {
      form.setValue("body_parts", [bodyParts[0].id]);
    }
  }, [bodyParts]);

  return (
    <>
      <form
        id="mri-search"
        ref={formRef}
        className="grid gap-y-4 rounded-2xl border border-neutral-300 bg-white p-5 lg:grid-cols-[1fr,2fr,1fr,120px] lg:items-center lg:divide-x lg:rounded-xl lg:p-2.5"
        action="/searches"
        acceptCharset="UTF-8"
        method="post"
        aria-errormessage={!!formError ? "form-error" : undefined}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <h2 className="col-span-full text-center text-lg font-medium -tracking-[0.045px] text-neutral-900 lg:hidden">
          Start your search
        </h2>

        <Controller
          name="scan_type"
          control={form.control}
          rules={{ required: true }}
          render={({ field: { name, onChange, value } }) => (
            <ScanTypeInput
              isGrouped
              showConsultationFooter={showConsultationFooter}
              name={name}
              availableScanTypes={scanTypes}
              errorId={
                form.formState.errors.scan_type ? "scan_type-error" : undefined
              }
              selectedScanTypeId={value ?? ""}
              displayValue={selectedScanType?.name}
              className="!border-l-0"
              setScanType={onChange}
            />
          )}
        />

        {form.watch("body_parts").map((item) => (
          <input key={item} type="hidden" value={item} name="search_inputs[]" />
        ))}

        <Controller
          name="body_parts"
          control={form.control}
          rules={{ required: "Please select a body part" }}
          render={({ field: { onChange, value } }) => (
            <div className={inputWrapperStyles(!!errors.body_parts?.message)}>
              <BodyPartInput
                isGrouped
                showConsultationFooter={showConsultationFooter}
                availableBodyParts={bodyParts}
                setSelectedBodyPartIds={onChange}
                selectedBodyPartIds={value}
                maxBodyParts={selectedScanType?.max_allowed_body_parts}
              />
              {errors.body_parts?.message && (
                <SystemMessage
                  type="error"
                  id="body_parts-error"
                  message={errors.body_parts.message}
                  className="lg:hidden"
                />
              )}
            </div>
          )}
        />

        {csrfToken ? (
          <input
            name="authenticity_token"
            type="hidden"
            value={csrfToken}
            className="hidden"
          />
        ) : null}

        <Controller
          name="area"
          control={form.control}
          rules={{ required: "Please enter a location" }}
          render={({ field: { name, onChange, value } }) => (
            <div className={inputWrapperStyles(!!errors.area?.message)}>
              <LocationInput
                name={name}
                onChange={onChange}
                errorId={errors.area ? "area-error" : undefined}
                isGrouped
                defaultValue={value}
              />
              {errors.area?.message && (
                <SystemMessage
                  type="error"
                  id="area-error"
                  message={errors.area.message}
                  className="lg:hidden"
                />
              )}
            </div>
          )}
        />

        <button
          type="submit"
          disabled={loading}
          className="button button-primary w-full !border-r lg:ml-1 lg:h-full lg:w-auto"
        >
          Search
        </button>
      </form>
      {formError && (
        <SystemMessage
          message={formError}
          type="error"
          id="form-error"
          className="mt-4 hidden lg:flex"
        />
      )}
    </>
  );
}

import { useJsApiLoader } from "@react-google-maps/api";
import * as React from "react";

export const GoogleScriptsContext = React.createContext(false);

interface Props {
  libraries: Libraries;
  children: React.ReactNode;
}

// `@react-google-maps/api` does not export this type, so we need
// to extract it from the hook type.
type Libraries = Parameters<typeof useJsApiLoader>[0]["libraries"];

/**
 * A context provider that loads the required Google scripts. Exposes the
 * loaded state in the context so it can be used to conditionally render
 * dependant components further down the tree.
 *
 */
export const GoogleScriptsProvider: React.FC<Props> = ({
  libraries,
  children,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.AppConfig.GOOGLE_API_KEY || "invalid",
    libraries,
  });

  return (
    <GoogleScriptsContext.Provider value={isLoaded}>
      {children}
    </GoogleScriptsContext.Provider>
  );
};

export const useIsGoogleScriptsLoaded = () =>
  React.useContext(GoogleScriptsContext);

import { Listbox } from "@headlessui/react";
import { IconArrowRight, IconChevronDown } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import type { ScanType } from "../types";
import {
  itemStyles,
  labelStyles,
  optionsStyles,
  targetStyles,
} from "./inputStyles";
import { ConsultationFooter } from "./ConsultationFooter";

interface ScanTypeInputProps {
  name?: string;
  errorId?: string;
  availableScanTypes: ScanType[];
  selectedScanTypeId: string;
  setScanType: (id: string) => void;
  defaultValue?: string;
  displayValue?: string;
  className?: string;
  inputClassName?: string;
  isGrouped?: boolean;
  showConsultationFooter?: boolean;
}

export function ScanTypeInput({
  name = "scanType",
  errorId,
  availableScanTypes,
  setScanType,
  selectedScanTypeId,
  displayValue = "",
  className,
  inputClassName,
  isGrouped,
  showConsultationFooter,
}: ScanTypeInputProps) {
  return (
    <Listbox
      data-testid="scan-type-selector"
      name={name}
      as="div"
      aria-invalid={!!errorId}
      aria-errormessage={errorId}
      className={classNames("relative", className)}
      defaultValue={selectedScanTypeId}
      onChange={setScanType}
    >
      <Listbox.Button
        className={targetStyles(isGrouped, isGrouped && "lg:pl-2")}
      >
        <div>
          {displayValue && <div className={labelStyles}>Scan Type</div>}
          {displayValue || "Scan Type"}
        </div>
        <IconChevronDown className="ml-auto h-6 w-6 shrink-0 text-neutral-700" />
      </Listbox.Button>
      <Listbox.Options
        className={classNames(
          optionsStyles,
          "max-h-[400px] scroll-p-20",
          showConsultationFooter && "pb-0"
        )}
      >
        {availableScanTypes.map(({ id, name }) => (
          <Listbox.Option
            key={id}
            value={id}
            className={(item) =>
              itemStyles(item, item.active && "bg-neutral-100")
            }
          >
            {name}
          </Listbox.Option>
        ))}

        {showConsultationFooter && <ConsultationFooter />}
      </Listbox.Options>
    </Listbox>
  );
}

import { useState } from "react";
import { useIsGoogleScriptsLoaded } from "./GoogleScriptsContext";
import { debounce } from "./Debounce";

export function useLocation(defaultLocation = "") {
  const [location, setLocation] = useState(defaultLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const scriptLoaded = useIsGoogleScriptsLoaded();

  async function geocodeLatLang(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder();

    try {
      const { results } = await geocoder.geocode({ location: { lat, lng } });

      if (!results[0]) {
        alert("No results found");
        return null;
      }

      let locality = results[0].address_components.filter(
        ({ types }) => types.includes("locality") && types.includes("political")
      )[0];

      locality ||= results[0].address_components.filter(({ types }) =>
        types.includes("postal_town")
      )[0];

      return locality.short_name;
    } catch (error) {
      console.error("Geocoder failed:", error);
      return null;
    }
  }

  function getLocation() {
    const success: PositionCallback = async ({ coords }) => {
      setIsLoading(true);

      const resolvedLocationName = await geocodeLatLang(
        coords.latitude,
        coords.longitude
      );

      setIsLoading(false);

      if (resolvedLocationName) {
        setLocation(resolvedLocationName);
      }
    };

    const error: PositionErrorCallback = (err) => {
      setIsLoading(false);
      console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    });
  }

  function searchPlaces(input?: string) {
    if (!input) {
      setSuggestions([]);
      return;
    }
    const places = new google.maps.places.AutocompleteService();

    const searchOptions = {
      input,
      componentRestrictions: { country: "uk" },
      types: ["(regions)"],
    };

    places.getPlacePredictions(searchOptions, (locs) =>
      setSuggestions(locs ?? [])
    );
  }

  const debouncedSearchPlaces = debounce<typeof searchPlaces>(
    searchPlaces,
    400
  );

  return {
    isLoading: !scriptLoaded || isLoading,
    getLocation,
    location,
    setLocation,
    searchPlaces: debouncedSearchPlaces,
    suggestions,
    scriptLoaded,
  };
}

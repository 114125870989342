import { Combobox } from "@headlessui/react";
import { IconButton } from "@scandotcom/react";
import { IconMapPin } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { useLocation } from "../../shared/useLocation";
import {
  itemStyles,
  labelStyles,
  optionsStyles,
  targetStyles,
} from "./inputStyles";

interface LocationInputProps {
  name?: string;
  defaultValue?: string;
  errorId?: string;
  className?: string;
  inputClassName?: string;
  onChange?: (value: string) => void;
  isGrouped?: boolean;
}

export function LocationInput({
  name = "location",
  defaultValue = "",
  errorId,
  className,
  inputClassName,
  onChange,
  isGrouped,
}: LocationInputProps) {
  const {
    getLocation,
    searchPlaces,
    suggestions,
    scriptLoaded,
    location,
    setLocation,
  } = useLocation(defaultValue);

  React.useEffect(() => {
    if (onChange && location) {
      onChange(location);
    }
  }, [location]);

  return (
    <Combobox
      name={name}
      data-testid="location-input"
      as="div"
      className={classNames("relative", className)}
      aria-invalid={!!errorId}
      aria-errormessage={errorId}
      value={location}
      onChange={setLocation}
    >
      <div className={targetStyles(isGrouped)}>
        <IconButton
          icon={IconMapPin}
          label="get location"
          className="ring-primary-300 h-7 w-7 shrink-0 rounded-lg text-neutral-700 transition hover:bg-neutral-200 focus:outline-none focus-visible:ring-2"
          onClick={getLocation}
        />
        <div className="flex-grow">
          <Combobox.Label className={labelStyles}>Location</Combobox.Label>
          <Combobox.Input
            placeholder="Postcode or town"
            autoComplete="off"
            className="w-full focus:outline-none"
            onChange={(event) => {
              setLocation(event.target.value);
              searchPlaces(event.target.value);
            }}
            displayValue={(_) => location}
          />
        </div>
      </div>
      {Boolean(suggestions.length) && scriptLoaded && (
        <Combobox.Options
          className={classNames(optionsStyles, "lg:w-[calc(100%+104px+10px)]")}
        >
          <Combobox.Option value={location} />

          {suggestions?.map((loc) => {
            const name = loc.description.replace(", UK", "");
            return (
              <Combobox.Option
                key={loc.place_id}
                value={name}
                className={itemStyles}
              >
                {name}
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      )}
    </Combobox>
  );
}
